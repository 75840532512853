<template>
	<b-modal
		:visible="true"
		centered
		modal-class="simple-feedback-modal"
		body-class="p-0"
		content-class="p-0"
		header-class="p-0"
		footer-class="p-0 border-0 mt-3"
		hide-header
		hide-footer
	>
		<div class="simple-feedback-modal__header d-flex justify-content-end w-100">
			<IconSax
				name="close-circle"
				color="var(--fontcolor)"
				@click="close"
			/>
		</div>

		<div class="simple-feedback-modal__content">
			<TextPlay
				v-if="title"
				weight="bold"
				size="xl"
				color="var(--fontcolor)"
				v-html="title"
			/>

			<TextPlay
				v-if="desc"
				color="var(--text-color)"
				v-html="desc"
			/>

			<div class="actions">
				<ButtonPlay
					v-if="confirmBtnText"
					:type="confirmBtnType"
					:icon="confirmBtnIcon"
					:iconType="confirmBtnIconType"
					:text="confirmBtnText"
					@click="$emit('confirm')"
				/>
				<ButtonPlay
					type="cancel"
					:text="$t('settings.webhooks.cancel')"
					@click="close"
				/>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
		},
		desc: {
			type: String,
		},
		confirmBtnType: {
			type: String,
			default: "normal",
		},
		confirmBtnText: {
			type: String,
		},
		confirmBtnIcon: {
			type: String,
		},
		confirmBtnIconType: {
			type: String,
		},
	},
	methods: {
		close() {
			this.$emit("close");
		},
	},
};
</script>

<style lang="scss">
.simple-feedback-modal {
	.modal-dialog {
		width: 415px;
	}

	.modal-content {
		padding: 16px 24px 40px 24px !important;
	}
}

@media (max-width: 768px) {
	.simple-feedback-modal {
		.modal-dialog {
			margin: auto;
			width: 85%;
		}
	}
}
</style>

<style lang="scss" scoped>
.simple-feedback-modal {
	&__header {
		.isax {
			cursor: pointer;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.actions {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}
}
</style>
