<template>
	<SimpleModalFeedback
		:title="$t('settings.webhooks.webhook_delete', { webhook: item.name })"
		:confirmBtnText="$t('settings.webhooks.delete_event')"
		confirmBtnIcon="trash"
		confirmBtnIconType="bold"
		confirmBtnType="danger"
		@close="$emit('close')"
		@confirm="handleDeleteWebhook"
	/>
</template>

<script>
import SimpleModalFeedback from "@/modals/template/SimpleFeedback.vue";
import { mapActions } from "vuex";

export default {
	components: {
		SimpleModalFeedback,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	methods: {
		...mapActions({
			deleteWebhook: "webhooks/deleteWebhook",
		}),
		async handleDeleteWebhook() {
			try {
				const resp = await this.deleteWebhook(this.item);

				if (!resp === "1") {
					throw new Error("Erro ao remover o evento");
				}

				this.$root.$bvToast.toast(this.$t('settings.webhooks.webhook_delete_success_desc'), {
					title: this.$t('settings.webhooks.webhook_delete_success'),
					variant: "success",
					autoHideDelay: 5000,
					appendToast: true,
				});

				this.$emit('close');
			} catch (error) {
				this.$bvToast.toast(this.$t('settings.webhooks.webhook_delete_error_desc'), {
					title: this.$t('settings.webhooks.webhook_delete_error'),
					variant: "danger",
					autoHideDelay: 5000,
					appendToast: true,
				});
			}
		},
	},
};
</script>
