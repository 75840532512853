<template>
	<b-modal
		:visible="true"
		modal-class="delete-site-modal"
		body-class="p-0"
		content-class="p-0"
		header-class="p-0"
		footer-class="p-0 border-0 mt-3"
		hide-header
		hide-footer
	>
		<div class="delete-site-modal__header d-flex justify-content-end w-100">
			<IconSax
				name="close-circle"
				color="var(--fontcolor)"
				@click="close"
			/>
		</div>

		<div class="delete-site-modal__content">
			<TextPlay
				weight="bold"
				size="xl"
				color="var(--fontcolor)"
				:text="$t('delete_site_modal.title')"
			/>
			<TextPlay
				color="var(--text-color)"
				:text="$t('delete_site_modal.desc')"
			/>

			<div class="info">
				<TextPlay
					color="var(--fontcolor)"
					v-html="$t('delete_site_modal.name', { name: selectedSite.name })"
				/>
				<TextPlay
					color="var(--fontcolor)"
					v-html="$t('delete_site_modal.subdomain', { subdomain: selectedSite.subdomain })"
				/>
			</div>

			<InputFormPlay
				:label="$t('delete_site_modal.confirm_your_subdomain')"
				:placeholder="$t('delete_site_modal.confirm_your_subdomain')"
				v-model="subdomain"
				name="subdomain"
				:validation="{ required: true }"
			/>

			<div class="actions">
				<button
					@click="toDelete"
					class="modal-delete-btn modal-delete-btn-danger"
				>
					<IconSax
						name="trash"
						type="bold"
						size="20"
						color="white"
					/>
					{{ $t("delete_site_modal.confirm") }}
				</button>
				<button
					@click="close"
					class="modal-delete-btn modal-delete-btn-cancel"
				>
					{{ $t("delete_site_modal.cancel") }}
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SiteService from "@/services/resources/SiteService";
const serviceSite = SiteService.build();

export default {
	data() {
		return {
			allSites: "",
			selectedSite: "",
			subdomain: "",
		};
	},
	computed: {
		...mapGetters({
			getMe: "auth/getMe",
		}),
	},
	mounted() {
		this.getCurrentSiteAndMember();
	},
	methods: {
		...mapActions({
			actionBlogSelected: "auth/blogSelected",
		}),
		close() {
			this.$emit("close");
		},
		getCurrentSiteAndMember() {
			this.selectedSite = this.getMe.current.currentSite;
			this.allSites = this.getMe;
		},
		async toDelete() {
			const isValid = await this.$validator._base.validateAll();

			try {
				if (!isValid) {
					throw new Error("Invalid form");
				}

				let data = {
					id: this.selectedSite.id + "?subdomain=" + this.subdomain,
				};

				await serviceSite.destroy(data);

				if (this.allSites.member.sites.length === 1) {
					this.$store.dispatch("auth/logoutRequest").catch(function () {});
				} else {
					const array = [];
					const sites = this.allSites.member.sites;
					for (let i = 0; i < sites.length; i++) {
						if (sites[i].id !== this.selectedSite.id) {
							array.push(sites[i]);
						}
					}
					this.actionBlogSelected(array[0]);
				}
				this.close();
				this.$root.$bvToast.toast(this.$t("delete_site_modal.plataform_was_deleted"), {
					title: this.$t("delete_site_modal.success_deleting"),
					variant: "success",
					autoHideDelay: 5000,
					appendToast: true,
				});
			} catch (error) {
				this.$bvToast.toast(this.$t("delete_site_modal.error_deleting_desc"), {
					title: this.$t("delete_site_modal.error_deleting"),
					variant: "danger",
					autoHideDelay: 5000,
					appendToast: true,
				});
			}
		},
	},
};
</script>

<style lang="scss">
.delete-site-modal {
	.modal-dialog {
		width: 415px;
	}

	.modal-content {
		padding: 16px 24px 40px 24px !important;
	}
}

@media (max-width: 768px) {
	.delete-site-modal {
		.modal-dialog {
			margin: auto;
			width: 85%;
		}
	}
}
</style>

<style lang="scss" scoped>
.delete-site-modal {
	&__header {
		.isax {
			cursor: pointer;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.info {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.actions {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.modal-delete-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding: 12px;
				border-radius: 8px;
				gap: 8px;
				font-size: 16px;
				font-weight: 600;
				cursor: pointer;
				border: none;
			}

			.modal-delete-btn-danger {
				background-color: var(--feedback-error-500);
				color: white;
			}

			.modal-delete-btn-cancel {
				[data-theme="dark"] & {
					background-color: var(--neutral-gray-800);
				}
				background-color: var(--neutral-gray-100);
				color: var(--fontcolor);
			}
		}
	}
}
</style>
