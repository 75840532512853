<template>
	<SimpleModalFeedback
		:title="$t('settings.webhooks.webhook_test')"
		:desc="$t('settings.webhooks.webhook_test_desc', { webhook: item.name })"
		:confirmBtnText="$t('settings.webhooks.send_test')"
		confirmBtnIcon="send-2"
		confirmBtnIconType="bold"
		@close="$emit('close')"
		@confirm="sendTest"
	/>
</template>

<script>
import SimpleModalFeedback from "@/modals/template/SimpleFeedback.vue";
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
	components: {
		SimpleModalFeedback,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	methods: {
		async sendTest() {
			let data = {
				id: `/webhook/${this.item.id}`,
			};

			try {
				const resp = await serviceCallback.update(data);

				if (!resp.success) {
					throw new Error("Erro ao enviar o evento");
				}

				this.$root.$bvToast.toast(this.$t('settings.webhooks.webhook_test_success_desc'), {
					title: this.$t('settings.webhooks.webhook_test_success'),
					variant: "success",
					autoHideDelay: 5000,
					appendToast: true,
				});

				this.$emit('close');
			} catch (error) {
				this.$bvToast.toast(this.$t('settings.webhooks.webhook_test_error_desc'), {
					title: this.$t('settings.webhooks.webhook_test_error'),
					variant: "danger",
					autoHideDelay: 5000,
					appendToast: true,
				});
			}
		},
	},
};
</script>
